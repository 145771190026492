import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/shared/Layout"
import { Container } from "react-bootstrap"
import { Heading48 } from "../styles/PageStyles"

const PrivacyPolicy = ({ data }) => {
  return (
    <Layout seoTitle="Privacy Policy">
      <StyledContainer fluid>
        <Heading48 style={{ textAlign: "center", marginBottom: 50 }}>
          {data.markdownRemark?.frontmatter?.title}
        </Heading48>
        <div
          className="body"
          dangerouslySetInnerHTML={{
            __html: data?.markdownRemark?.html,
          }}
        />
      </StyledContainer>
    </Layout>
  )
}

export default PrivacyPolicy

const StyledContainer = styled(Container)`
  padding: 165px 10% 75px 10%;

  * {
    text-align: justify;
    margin-bottom: 1rem;
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    .body {
      p,
      li {
        font-size: 14px;
      }
    }
  }
`

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "privacy-policy" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
